import { join, head, split, compose, init, last } from "ramda";

export const modifyImageUrl = ({ url, size = 1000 }) => {
  if (url) {
    const insertSizeBeforeExtension = (parts) => [
      join(".", init(parts)) + `_x${size}`,
      last(parts),
    ];
    return compose(
      join("."),
      insertSizeBeforeExtension,
      split("."),
      head,
      split("?"),
    )(url);
  }
  return url;
};
