import styled from "@emotion/styled";

export const AspectRatioBox = styled.div`
  position: relative;
  width: 100%;
  &:before {
    content: "";
    display: block;
    padding-top: 140%;
  }
`;
