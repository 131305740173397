import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

const ProductPrice = styled(Box)`
  display: flex;
  flex-direction: row;
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.2em;
  font-size: 12px;
  margin-top: 2px;
  text-align: left;
  justify-content: left;
  align-items: baseline;
`;

const MRPPrice = styled(Typography)`
  color: #121212;
  text-decoration: line-through;
  font-family: Inter;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.04em !important;
  @media only screen and (max-width: 600px) {
    font-size: 15px;
  }
`;

const SalePrice = styled(Typography)`
  margin-left: 10px;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  margin-left: 8px;
  text-align: left;
  @media only screen and (max-width: 600px) {
    font-size: 15px;
  }
`;

const GroupPrice = styled(Typography)`
  font-family: Inter !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #121212 !important;
  text-transform: capitalize;
  padding-right: 0.4rem;
  text-align: left;
  @media only screen and (max-width: 600px) {
    font-size: 15px !important;
  }
`;

const DefaultPrice = styled(Typography)`
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #121212;
  line-height: 2;
  text-align: left;
  @media only screen and (max-width: 600px) {
    font-size: 15px;
  }
`;

const Discount = styled(Typography)`
  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  color: #c20000;
  line-height: 1.5;
  margin-left: 8px;
  @media only screen and (max-width: 600px) {
    font-size: 15px;
  }
`;

function formatCurrency(amount) {
  const fixedAmount = Number(amount).toFixed(2);
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  }).format(fixedAmount);
  return formattedAmount.replace(/₹\s?/, "$ ") + " USD";
}

export const priceFormatter = (price) =>
  `$ ${price
    .toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    })
    .split("₹")
    .join("")} USD`;

export const calculateDiscount = (price, compareAtPrice) => {
  return (((compareAtPrice - price) / compareAtPrice) * 100).toFixed(0);
};

export function FormatedPriceVariant({ price, compareAtPrice }) {
  return (
    <ProductPrice className="price-main C005-price">
      {compareAtPrice &&
      compareAtPrice !== "0.00" &&
      price &&
      compareAtPrice !== price ? (
        <>
          <SalePrice className="sale">{formatCurrency(price)}</SalePrice>
          <MRPPrice className="mrp">{formatCurrency(compareAtPrice)}</MRPPrice>
          <Discount className="discount">
            Save {calculateDiscount(price, compareAtPrice)}%
          </Discount>
        </>
      ) : (
        <DefaultPrice className="default">{formatCurrency(price)}</DefaultPrice>
      )}
    </ProductPrice>
  );
}

function FormatedPrice({ price }) {
  if (price.MRP === price.price) {
    return (
      <DefaultPrice className="default">
        {priceFormatter(price.price)}
      </DefaultPrice>
    );
  }
  return (
    <>
      <MRPPrice className="mrp">{priceFormatter(price.MRP)}</MRPPrice>
      <SalePrice className="sale">{priceFormatter(price.price)}</SalePrice>
      <Discount className="discount">
        Save {calculateDiscount(price.price, price.MRP)}%
      </Discount>
    </>
  );
}

export function getPrice({ priceList = [] }) {
  if (priceList.length === 1) {
    const [price] = priceList;
    return (
      <ProductPrice className="price-main">
        <FormatedPrice price={price} />
      </ProductPrice>
    );
  } else {
    const [minPrice] = priceList;
    return (
      <ProductPrice className="price-main">
        <GroupPrice>From - </GroupPrice>
        <FormatedPrice price={minPrice} />
      </ProductPrice>
    );
  }
}
