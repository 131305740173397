"use client";
import Link from "next/link";
import Image from "next/image";
import styled from "@emotion/styled";
import {
  Container as MuiContainer,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Tooltip,
  Chip as MuiChip,
} from "@mui/material";
import { InView } from "react-intersection-observer";
import { Fragment } from "react";
import { sendGTMEvent } from "@next/third-parties/google";
import { compose, filter, path, prop, propOr, splitEvery } from "ramda";

import { getPrice } from "utils/price";
import { modifyImageUrl } from "utils/shopifyCDN";
import { productClick, productView } from "utils/dataLayer";

import Banners from "./Banners";
import { AspectRatioBox } from "./AspectRatioBox";

const Container = styled(MuiContainer)`
  text-align: center;
  padding: 2.5rem !important;
  @media only screen and (max-width: 600px) {
    padding: 0rem 1rem 1rem !important;
  }
  @media only screen and (min-width: 1600px) {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  @media only screen and (min-width: 1900px) {
    padding-left: 21rem !important;
    padding-right: 21rem !important;
  }
`;

const Header = styled(Typography)`
  font-family: Inter;
  font-weight: 400;
  text-transform: capitalize;
  color: #121212;
  letter-spacing: 0.04em;
  font-size: 30px;
  margin-top: 5px;
  margin-bottom: 50px;
  line-height: 1.6;
  @media only screen and (max-width: 1000px) {
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 40px;
    font-size: 24px;
  }
`;

const ProductTitle = styled(Typography)`
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #121212;
  letter-spacing: 0.025em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  line-height: 1.9;
  text-align: left;
  @media only screen and (max-width: 600px) {
    font-size: 1em;
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  z-index: 1;
  right: 0;
  border-radius: 1px;
  background: ${({ notavailable }) =>
    notavailable === "true" ? "#fff" : "#000"};
  color: ${({ notavailable }) => (notavailable === "true" ? "#000" : "#fff")};
  font-family: Assitant;
  font-weight: 400;
  @media only screen and (max-width: 600px) {
    font-size: 10px;
    height: 18px;
  }
`;

const CardDetails = ({
  item,
  gridIdx,
  gridItem,
  idx,
  productId,
  isMobile,
  collection,
}) => {
  const isAvaialble = compose(
    filter(prop("availability")),
    propOr([], "availability"),
  )(item || {});
  return (
    <Link
      href={`/products/${item.id}`}
      prefetch={true}
      className={`C007-${gridIdx}-${idx}`}
    >
      <Card
        elevation={0}
        className={`C007-${gridIdx}-${idx}`}
        style={{ marginBottom: "10px" }}
      >
        <CardActionArea
          onClick={() =>
            sendGTMEvent(
              productClick({
                data: item,
                position: !isMobile
                  ? gridIdx * gridItem.length + (idx + 1)
                  : gridIdx * 2 + (idx + 1) - Math.floor(gridIdx / 3),
                productId,
              }),
            )
          }
        >
          {item.price[0].MRP !== item.price[0].price && isAvaialble.length ? (
            <Chip
              size="small"
              label={`Sale`}
              className={`C007-${gridIdx}-${idx}-sale`}
            ></Chip>
          ) : null}
          {!isAvaialble.length ? (
            <Chip
              size="small"
              label={`Sold out`}
              notavailable={"true"}
              className={`C007-${gridIdx}-${idx}-sold`}
            ></Chip>
          ) : null}
          <AspectRatioBox className={`C007-${gridIdx}-${idx}-image`}>
            <Image
              src={
                modifyImageUrl({
                  url: path(["images", 0, "src"])(item),
                  size: 400,
                }) || "/static/default.jpg"
              }
              className={`C007-${gridIdx}-${idx}-image`}
              alt={item.title}
              fill
              placeholder="blur"
              blurDataURL={
                "data:image/svg+xml;base64,CiAgICA8c3ZnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDggNSc+CiAgICAgIDxmaWx0ZXIgaWQ9J2InIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0nc1JHQic+CiAgICAgICAgPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0nMScgLz4KICAgICAgPC9maWx0ZXI+CgogICAgICA8aW1hZ2UgcHJlc2VydmVBc3BlY3RSYXRpbz0nbm9uZScgZmlsdGVyPSd1cmwoI2IpJyB4PScwJyB5PScwJyBoZWlnaHQ9JzEwMCUnIHdpZHRoPScxMDAlJyAKICAgICAgaHJlZj0nZGF0YTppbWFnZS9hdmlmO2Jhc2U2NCwvOWovMndCREFBZ0dCZ2NHQlFnSEJ3Y0pDUWdLREJRTkRBc0xEQmtTRXc4VUhSb2ZIaDBhSEJ3Z0pDNG5JQ0lzSXh3Y0tEY3BMREF4TkRRMEh5YzVQVGd5UEM0ek5ETC8yd0JEQVFrSkNRd0xEQmdORFJneUlSd2hNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpML3dBQVJDQUFMQUJBREFTSUFBaEVCQXhFQi84UUFGZ0FCQVFFQUFBQUFBQUFBQUFBQUFBQUFCZ01ILzhRQUloQUFBZ0lDQWdFRkFRQUFBQUFBQUFBQUFRSURCQVVSQUNFU0JoTVVNVUhCLzhRQUZRRUJBUUFBQUFBQUFBQUFBQUFBQUFBQUFBTC94QUFaRVFBREFBTUFBQUFBQUFBQUFBQUFBQUFBQVJFQ0lUSC8yZ0FNQXdFQUFoRURFUUEvQU5KdFhNbEZqekxjaGZIMVl4dDVQa3B2ZjUzL0FEWGZJeGVzemtFclJZK3V0eVYxVVNsU3dDc1U4aHM2ME5nRTY0aEVVZCtrOWEzR2swRWkrTG82Z2dnOWNNNTJOYU9GdFdxbzltWlN6cXlIV2pvOWdmWDd3M3VsNHpoLy85az0nIC8+CiAgICA8L3N2Zz4KICA="
              }
              priority={collection && gridIdx < 4 ? true : false}
              style={{
                objectFit: "cover",
              }}
              fetchPriority={collection && gridIdx < 4 ? "high" : "low"}
              loading={collection && gridIdx < 4 ? "eager" : "lazy"}
              unoptimized={true}
            />
          </AspectRatioBox>
          <CardContent
            sx={{
              p: "0.5rem",
              textAlign: "left",
              background: "rgb(243, 243, 243)",
            }}
            className={`C007-${gridIdx}-${idx}`}
          >
            <Tooltip
              title={item.title}
              className={`C007-${gridIdx}-${idx}-tooltip`}
            >
              <ProductTitle
                component="div"
                className={`C007-${gridIdx}-${idx}-title`}
              >
                {item.title}
              </ProductTitle>
            </Tooltip>
            <Typography
              component="div"
              textAlign="center"
              className={`C007-${gridIdx}-${idx}-price`}
            >
              {getPrice({ priceList: item.price })}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

function splitArrayIntoPattern(arr, pattern) {
  let result = [];
  let index = 0;
  while (index < arr.length) {
    for (let p of pattern) {
      if (index + p > arr.length) {
        result.push(arr.slice(index));
        return result;
      }
      result.push(arr.slice(index, index + p));
      index += p;
    }
  }
  return result;
}

export default function ImageGrid({
  data = [],
  productId,
  isMobile,
  collection,
  related,
  relatedDrawer,
}) {
  const gridData = isMobile
    ? splitArrayIntoPattern(data, [2, 2, 1])
    : splitEvery(2, data);
  return (
    <>
      <Container maxWidth="1400px" className="C007">
        {!collection && !relatedDrawer ? (
          <Header className="C007-title">You may also like</Header>
        ) : null}
        <Grid
          container
          rowSpacing={0.5}
          columnSpacing={1}
          justifyContent="center"
          className="C007"
        >
          {gridData.map((gridItem, gridIdx) => (
            <InView
              onChange={(inView) => {
                if (inView) {
                  sendGTMEvent(
                    productView({
                      data: gridItem.map((item, itemIdx) => {
                        return {
                          ...item,
                          position: !isMobile
                            ? gridIdx * gridItem.length + (itemIdx + 1)
                            : gridIdx * 2 +
                              (itemIdx + 1) -
                              Math.floor(gridIdx / 3),
                        };
                      }),
                      productId,
                    }),
                  );
                }
              }}
              threshold={0.3}
              key={`realted-item-${gridIdx}`}
              className={`C007-${gridIdx}`}
            >
              {({ ref }) =>
                gridItem.map((item, idx) => (
                  <Fragment key={`realted-item-row-${idx}`}>
                    {(gridIdx + 1) % 3 !== 0 ? (
                      <Grid
                        item
                        xs={6}
                        lg={3}
                        ref={ref}
                        className={`C007-${gridIdx}-${idx}`}
                      >
                        <CardDetails
                          item={item}
                          gridIdx={gridIdx}
                          gridItem={gridItem}
                          idx={idx}
                          productId={productId}
                          isMobile={isMobile}
                          collection={collection}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        ref={ref}
                        className={`C007-${gridIdx}-${idx}-featured`}
                      >
                        <CardDetails
                          item={item}
                          gridIdx={gridIdx}
                          gridItem={gridItem}
                          idx={idx}
                          productId={productId}
                          isMobile={isMobile}
                          collection={collection}
                        />
                      </Grid>
                    )}
                  </Fragment>
                ))
              }
            </InView>
          ))}
        </Grid>
      </Container>
      <Banners related={related} isMobile={isMobile} />
    </>
  );
}
