export const productClick = ({
  data,
  event = "productClick",
  position,
  productId,
}) => {
  return {
    event,
    ecommerce: {
      click: {
        actionField: {
          list: `Related Products - ${productId}`,
        },
        products: [
          {
            id: data.id,
            name: data.title,
            price: data.price[0].price,
            brand: data.vendor,
            position,
          },
        ],
      },
    },
  };
};

export const productView = ({ data, event = "productListView", productId }) => {
  return {
    event,
    ecommerce: {
      impressions: data.map((item) => ({
        id: item.id,
        name: item.title,
        price: item.price[0].price,
        brand: item.vendor,
        position: item.position,
        list: `Related Products - ${productId}`,
      })),
    },
  };
};

export const promotionEvent = ({ data, event = "view_promotion" }) => {
  return {
    event,
    ecommerce: {
      creative_name: data.image,
      creative_slot: data.index,
      promotion_id: data.id,
      promotion_name: data.title,
    },
  };
};

export const productDetailView = ({
  data,
  event = "view_item_GA4_merca",
  eventId,
  variantId,
}) => {
  return {
    event,
    eventId,
    ecommerce: {
      items: [
        {
          item_id: data.id,
          item_name: data.title,
          index: 0,
          item_brand: data.vendor,
          item_variant: variantId,
          price: data.price[0].price,
          quantity: 1,
        },
      ],
    },
  };
};

export const addToCart = ({
  data,
  event = "add_to_cart_GA4_merca",
  eventId,
  value,
}) => {
  return {
    event,
    eventId,
    ecommerce: {
      currency: "INR",
      value,
      items: [
        {
          item_id: data.productId,
          item_name: data.title,
          index: 0,
          item_brand: data.vendor,
          item_variant: data.id,
          price: data.price,
          quantity: data.quantity,
        },
      ],
    },
  };
};
