import styled from "@emotion/styled";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { sendGTMEvent } from "@next/third-parties/google";
import Image from "next/image";
import Link from "next/link";
import { Fragment } from "react";
import { InView } from "react-intersection-observer";

import { promotionEvent } from "utils/dataLayer";
import { modifyImageUrl } from "utils/shopifyCDN";

import { AspectRatioBox } from "./AspectRatioBox";

const BoxContainer = styled(Box)`
  text-align: center;
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  padding-bottom: 3.5rem !important;
  @media only screen and (max-width: 600px) {
    padding: 0rem 1rem 1rem !important;
  }
  @media only screen and (min-width: 1600px) {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  @media only screen and (min-width: 1900px) {
    padding-left: 21rem !important;
    padding-right: 21rem !important;
  }
`;

const CollectionsTitle = styled(Typography)`
  font-family: Inter;
  font-weight: 400;
  text-transform: uppercase;
  color: #121212;
  letter-spacing: 0.04em;
  font-size: 20px;
  line-height: 1.6;
  margin-top: 40px;
  margin-bottom: 40px;
  @media only screen and (max-width: 600px) {
    font-size: 18.5px;
    margin: 30px 0;
  }
`;

const BannerText = styled(Typography)`
  display: flex;
  font-family: Inter;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  font-size: 22px;
  text-shadow: 0 0 50px #000;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 1.6;
  @media only screen and (max-width: 600px) {
    font-size: 16.4px;
  }
`;

export default function Banners({ related, isMobile }) {
  return (
    <>
      {related && related.length ? (
        <>
          <Divider
            sx={{ marginTop: isMobile ? "20px" : "40px" }}
            className="C009"
          />
          <BoxContainer className="C009">
            <CollectionsTitle className="C009-title">
              See More Collections
            </CollectionsTitle>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              className="C009"
            >
              {related.map((item, idx) => {
                return (
                  <InView
                    onChange={(inView) => {
                      if (inView) {
                        sendGTMEvent(
                          promotionEvent({
                            event: "view_promotion",
                            data: {
                              ...item,
                              index: idx + 1,
                            },
                          }),
                        );
                      }
                    }}
                    threshold={0.3}
                    key={`realted-item-${idx}`}
                    className={`C009-${idx}`}
                  >
                    {({ ref }) => (
                      <Fragment key={item.id}>
                        <Grid
                          item
                          xs={12}
                          lg={4}
                          ref={ref}
                          className={`C009-${idx}`}
                        >
                          <Link
                            href={`/collections/${item.id}`}
                            onClick={() => {
                              sendGTMEvent(
                                promotionEvent({
                                  event: "select_promotion",
                                  data: {
                                    ...item,
                                    index: idx + 1,
                                  },
                                }),
                              );
                            }}
                            className={`C009-${idx}`}
                          >
                            <AspectRatioBox className={`C009-${idx}`}>
                              <Box
                                position="absolute"
                                bottom="10px"
                                display="flex"
                                width="100%"
                                textAlign="center"
                                alignItems="center"
                                justifyContent="center"
                                zIndex={1}
                                className={`C009-${idx}`}
                              >
                                <Box
                                  bgcolor="rgb(1, 1, 1, 0.3)"
                                  width="100%"
                                  borderRadius="3px"
                                  p="5px 10px"
                                  className={`C009-${idx}`}
                                >
                                  <BannerText className={`C009-${idx}-title`}>
                                    {item.title}
                                  </BannerText>
                                </Box>
                              </Box>
                              <Image
                                src={
                                  modifyImageUrl({
                                    url: item.image,
                                    size: isMobile ? 500 : 900,
                                  }) || "/static/default.jpg"
                                }
                                alt={item.title}
                                fill
                                priority={false}
                                style={{
                                  objectFit: "cover",
                                }}
                                fetchPriority="low"
                                loading="lazy"
                                unoptimized={true}
                                className={`C009-${idx}-image`}
                              />
                            </AspectRatioBox>
                          </Link>
                        </Grid>
                      </Fragment>
                    )}
                  </InView>
                );
              })}
            </Grid>
          </BoxContainer>
        </>
      ) : null}
    </>
  );
}
